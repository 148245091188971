<script setup lang="ts">
const router = useRouter()
const user = await getCurrentUser()
const dropdownItem = [
  [{
    label: 'Profile',
    avatar: user.isAnonymous ? null : { src: user.photoURL },
    click: () => {
      router.push({ path: '/' })
    }
  }],
  [{
    label: 'Logout',
    click: () => {
      router.push({ path: '/logout' })
    }
  }]
]
</script>
<template>
  <div
    class="w-full flex flex-col justify-center m-auto"
    style="max-width: 800px;"
  >
    <header class="py-4 text-center relative z-50">
      <div class="container mx-auto flex justify-between items-center">
        <img
          src="../assets/images/SHEL.svg"
          alt="Logo"
          class="ml-2 w-32"
          @click="router.push({path: '/'})"
        >
        <UDropdown :items="dropdownItem">
          <UButton color="white" :label="user.displayName || 'Anonymous'" trailing-icon="i-heroicons-chevron-down-20-solid" />
        </UDropdown>
      </div>
    </header>
  </div>
</template>
