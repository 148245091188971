<script setup lang="ts">
const route = useRoute()
</script>
<template>
  <div class="bottom-0 left-0 w-full flex justify-center text-n-10">
    <div style="width: 800px;">
      <UDivider />
      <footer class="flex justify-evenly py-4">
        <RouterLink to="/">
          <UIcon
            class="h-8 w-8"
            :name="route.name === 'index' || route.name === 'login' ? 'i-heroicons-home-solid' : 'i-heroicons-home'"
          />
        </RouterLink>
        <NuxtLink to="/camera">
          <UIcon
            class="h-8 w-8"
            name="i-heroicons-camera"
          />
        </NuxtLink>
        <NuxtLink to="/search">
          <UIcon
            class="h-8 w-8"
            :name="route.name === 'search' ? 'i-heroicons-magnifying-glass-plus-20-solid' : 'i-heroicons-magnifying-glass-plus'"
          />
        </NuxtLink>
        <NuxtLink to="/bookshelf">
          <UIcon
            class="h-8 w-8"
            :name="route.name === 'bookshelf-id' || route.name === 'bookshelf' ? 'i-heroicons-book-open-solid': 'i-heroicons-book-open'"
          />
        </NuxtLink>
      </footer>
    </div>
  </div>
</template>
