<script setup lang="ts">

const router = useRouter()
const show = computed(() => {
	return router.currentRoute.value.path.split('/').pop() !== 'camera'
})

</script>

<template>
  <div class="app-wrapper h-screen w-full flex flex-col overflow-hidden bg-n-87">
    <HeaderComponent v-if="show" />
    <div class="flex-grow overflow-auto relative bg-n-87">
      <slot />
    </div>
    <FooterComponent v-if="show" />
  </div>
</template>

<style scoped>
.auth-container img {
	filter: invert(100%) sepia(100%) saturate(150%) hue-rotate(-180deg);
}

.app-wrapper {
	padding: 0;
}

</style>
